
export class Opinion {

    constructor(name, role, image, content) {
        this.name = name;
        this.role = role;
        this.image = image;
        this.content = content;
    }

}
